import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { Amplify } from "aws-amplify";

Amplify.configure({
	aws_appsync_graphqlEndpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
	aws_appsync_region: process.env.REACT_APP_APPSYNC_REGION,
	aws_appsync_authenticationType: process.env.REACT_APP_APPSYNC_AUTH_TYPE,
	aws_appsync_apiKey: process.env.REACT_APP_APPSYNC_API_KEY,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
